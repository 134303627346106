import Axios from "axios"
import interceptors from "../interceptors"

const httpClient = Axios.create({
    // baseURL: "http://localapi-new.fia.ba:44370/api",
    baseURL: "https://api.fia.ba/api",
    //baseURL: "https://test-api.fia.ba/api",
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

httpClient.interceptors.request.use(interceptors.requestInterceptor, interceptors.requestErrorInterceptor);
httpClient.interceptors.response.use(interceptors.responseInterceptor, interceptors.responseErrorInterceptor);

export default httpClient;
