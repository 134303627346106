const businessUnits = [
    {
        id: 1,
        businessUnit: "Bihać",
        address: "Petog korpusa bb",
        phone: "+387 37 223 319",
        parentId: null,
        ppt: "77000",
        positionX: 13.5,
        positionY: 23.5,
        icon: "fa fa-building"
    },
    {
        id: 2,
        businessUnit: "Orašje",
        address: "5.Ulica br.19",
        phone: "+387 31 712 438",
        parentId: null,
        ppt: "76270",
        positionX: 73,
        positionY: 14,
        icon: "fa fa-building"
    },
    {
        id: 3,
        businessUnit: "Tuzla",
        address: "Turalibegova br. 40",
        phone: "+387 35 277 282",
        parentId: null,
        ppt: "75000",
        positionX: 73,
        positionY: 31,
        icon: "fa fa-building"
    },
    {
        id: 4,
        businessUnit: "Zenica",
        address: "Masarikova 37",
        phone: "+387 32 244 488",
        parentId: null,
        ppt: "72000",
        positionX: 55,
        positionY: 41,
        icon: "fa fa-building"
    },
    {
        id: 5,
        businessUnit: "Goražde",
        address: "Trg branilaca bb",
        phone: "+387 38 221 010",
        parentId: null,
        ppt: "73000",
        positionX: 77.5,
        positionY: 59,
        icon: "fa fa-building"
    },
    {
        id: 6,
        businessUnit: "Travnik",
        address: "Vezira 62/A",
        phone: "+387 30 511 211",
        parentId: null,
        ppt: "72270",
        positionX: 49,
        positionY: 40,
        icon: "fa fa-building"
    },
    {
        id: 7,
        businessUnit: "Mostar poslovnica",
        address: "Dubrovačka bb",
        phone: "+387 36 806 858",
        parentId: null,
        ppt: "88000",
        positionX: 52,
        positionY: 68.5,
        icon: "fa fa-building"
    },
    {
        id: 8,
        businessUnit: "Široki Brijeg",
        address: "Ulica pobijenih franjevaca bb",
        phone: "+387 39 703 423",
        parentId: null,
        ppt: "88220",
        positionX: 45,
        positionY: 67,
        icon: "fa fa-building"
    },
    {
        id: 9,
        businessUnit: "Sarajevo",
        address: "Ložionička 3",
        phone: "+387 33 255 205",
        parentId: null,
        ppt: "71000",
        positionX: 67,
        positionY: 52.5,
        icon: "fa fa-building"
    },
    {
        id: 10,
        businessUnit: "Livno",
        address: "Trg domovinskog rata 5",
        phone: "+387 34 202 460",
        parentId: null,
        ppt: "80101",
        positionX: 33,
        positionY: 52,
        icon: "fa fa-building"
    },
    // Finished main

    //Children of Id = 1
    {
        id: 11,
        businessUnit: "Cazin",
        address: "Cazinskih brigada bb",
        phone: "+387 37 512 980",
        parentId: 1,
        ppt: "77220",
        positionX: 9,
        positionY: 16,
        icon: "fa fa-map-marker"
    },
    {
        id: 12,
        businessUnit: "Velika kladuša",
        address: "Hašima Okanovića br. 5",
        phone: "+387 37 772 943",
        parentId: 1,
        ppt: "77230",
        positionX: 7,
        positionY: 10,
        icon: "fa fa-map-marker"
    },
    {
        id: 13,
        businessUnit: "Bosanska krupa",
        address: "511. Slavne brigade",
        phone: "+387 37 471 114",
        parentId: 1,
        ppt: "77240",
        positionX: 12.5,
        positionY: 18,
        icon: "fa fa-map-marker"
    },
    {
        id: 14,
        businessUnit: "Ključ",
        address: "Alije Izetbegovića br. 1",
        phone: "+387 37 661 023",
        parentId: 1,
        ppt: "79280",
        positionX: 28,
        positionY: 30,
        icon: "fa fa-map-marker"
    },
    {
        id: 15,
        businessUnit: "Sanski most",
        address: "Trg broj 2",
        phone: "+387 37 685 532",
        parentId: 1,
        ppt: "79260",
        positionX: 26,
        positionY: 23.5,
        icon: "fa fa-map-marker"
    },
    //children of Id 2
    {
        id: 16,
        businessUnit: "Odžak",
        address: "Trg broj 2",
        phone: "+387 31 761 031",
        parentId: 2,
        ppt: "76290",
        positionX: 65.5,
        positionY: 14,
        icon: "fa fa-map-marker"
    },
    //children of Id 3
    {
        id: 17,
        businessUnit: "Gradačac",
        address: "Titova bb",
        phone: "+387 35 821 485",
        parentId: 3,
        ppt: "76250",
        positionX: 67.5,
        positionY: 19,
        icon: "fa fa-map-marker"
    },
    {
        id: 18,
        businessUnit: "Lukavac",
        address: "Lukavačkih brigada bb",
        phone: "+387 35 553 376",
        parentId: 3,
        ppt: "75300",
        positionX: 70,
        positionY: 30.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 19,
        businessUnit: "Srebrenik",
        address: "Srebreni grad bb",
        phone: "+387 35 643 204",
        parentId: 3,
        ppt: "75350",
        positionX: 69.5,
        positionY: 25,
        icon: "fa fa-map-marker"
    },
    {
        id: 20,
        businessUnit: "Živinice",
        address: "Ulica oslobođenja br. 66",
        phone: "+387 35 772 153",
        parentId: 3,
        ppt: "75270",
        positionX: 72,
        positionY: 34,
        icon: "fa fa-map-marker"
    },
    {
        id: 21,
        businessUnit: "Gračanica",
        address: "Bosanskih kraljeva bb",
        phone: "+387 35 703 831",
        parentId: 3,
        ppt: "75320",
        positionX: 64,
        positionY: 24.5,
        icon: "fa fa-map-marker"
    },
    //children of Id 4
    {
        id: 22,
        businessUnit: "Kakanj",
        address: "Alije Izetbegovića P+4+M",
        phone: "+387 32 465 454",
        parentId: 4,
        ppt: "72240",
        positionX: 59,
        positionY: 43,
        icon: "fa fa-map-marker"
    },
    {
        id: 23,
        businessUnit: "Tešanj",
        address: "Braće Pobrića bb",
        phone: "+387 32 650 557",
        parentId: 4,
        ppt: "74260",
        positionX: 57.5,
        positionY: 27.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 24,
        businessUnit: "Visoko",
        address: "Aleja šehida Resula br. 4",
        phone: "+387 32 735 606",
        parentId: 4,
        ppt: "71300",
        positionX: 61.5,
        positionY: 46,
        icon: "fa fa-map-marker"
    },
    {
        id: 25,
        businessUnit: "Zavidovići",
        address: "Naselje Stadion L-1",
        phone: "+387 32 878 155",
        parentId: 4,
        ppt: "72220",
        positionX: 61,
        positionY: 31.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 26,
        businessUnit: "Žepče",
        address: "Stjepana Tomaševiča bb",
        phone: "+387 32 880 792",
        parentId: 4,
        ppt: "72230",
        positionX: 58.5,
        positionY: 32,
        icon: "fa fa-map-marker"
    },
    // {
    //     id: 27,
    //     businessUnit: "Breza",
    //     address: "Alije Izetbegovića",
    //     phone: "+387 32 465 453",
    //     parentId: 4,
    //     ppt: "71370",
    //     positionX: 64,
    //     positionY: 45,
    //     icon: "fa fa-map-marker"
    // },

    //children of Id 5 do not exist
    //children of Id 6
    {
        id: 28,
        businessUnit: "Bugojno",
        address: "Bosanska bb",
        phone: "+387 30 251 422",
        parentId: 6,
        ppt: "70230",
        positionX: 45,
        positionY: 45,
        icon: "fa fa-map-marker"
    },
    {
        id: 29,
        businessUnit: "Fojnica",
        address: "Mehmeda Spahe br. 2",
        phone: "+387 30 831 889",
        parentId: 6,
        ppt: "71270",
        positionX: 53,
        positionY: 47,
        icon: "fa fa-map-marker"
    },
    {
        id: 30,
        businessUnit: "Kiseljak",
        address: "Šar bb",
        phone: "+387 30 879 142",
        parentId: 6,
        ppt: "71250",
        positionX: 57,
        positionY: 48,
        icon: "fa fa-map-marker"
    },
    {
        id: 31,
        businessUnit: "Vitez",
        address: "Fra Grge Martića bb",
        phone: "+387 30 711 103",
        parentId: 6,
        ppt: "72250",
        positionX: 51.5,
        positionY: 43,
        icon: "fa fa-map-marker"
    },
    {
        id: 32,
        businessUnit: "Jajce",
        address: "Starogradska 3",
        phone: "+387 30 658 177",
        parentId: 6,
        ppt: "70101",
        positionX: 40,
        positionY: 37.5,
        icon: "fa fa-map-marker"
    },
    //children of Id 7
    {
        id: 33,
        businessUnit: "Mostar ispostava 1",
        address: "Dubrovačka bb",
        phone: "+387 36 281 375",
        parentId: 7,
        ppt: "88000",
        positionX: 50,
        positionY: 68,
        icon: "fa fa-map-marker"
    },
    {
        id: 34,
        businessUnit: "Mostar ispostava 2",
        address: "Maršala Tita 91",
        phone: "+387 36 551 026",
        parentId: 7,
        ppt: "88000",
        positionX: 52,
        positionY: 66.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 35,
        businessUnit: "Čitluk",
        address: "Kralja Tomislava bb",
        phone: "+387 36 642 313",
        parentId: 7,
        ppt: "88260",
        positionX: 50,
        positionY: 72,
        icon: "fa fa-map-marker"
    },
    {
        id: 36,
        businessUnit: "Konjic",
        address: "Trg državnosti - Alije Izetbegovića bb",
        phone: "+387 36 726 220",
        parentId: 7,
        ppt: "88400",
        positionX: 56,
        positionY: 57.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 37,
        businessUnit: "Jablanica",
        address: "Trg oslobođenja bb",
        phone: "+387 36 281 381",
        parentId: 7,
        ppt: "88420",
        positionX: 50,
        positionY: 57,
        icon: "fa fa-map-marker"
    },
    {
        id: 38,
        businessUnit: "Prozor",
        address: "Splitska 1",
        phone: "+387 36 771 093",
        parentId: 7,
        ppt: "88440",
        positionX: 47.5,
        positionY: 52.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 39,
        businessUnit: "Stolac",
        address: "Banovinska bb",
        phone: "+387 36 853 071",
        parentId: 7,
        ppt: "88360",
        positionX: 56,
        positionY: 78.5,
        icon: "fa fa-map-marker"
    },
    {
        id: 40,
        businessUnit: "Čapljina",
        address: "Dr A.Starčevića bb",
        phone: "+387 36 806 858",
        parentId: 7,
        ppt: "88300",
        positionX: 51,
        positionY: 77,
        icon: "fa fa-map-marker"
    },
    //children of Id 8
    {
        id: 41,
        businessUnit: "Grude",
        address: "Dr Franje Tuđmana bb",
        phone: "+387 39 661 904",
        parentId: 8,
        ppt: "88340",
        positionX: 42.5,
        positionY: 68,
        icon: "fa fa-map-marker"
    },
    {
        id: 42,
        businessUnit: "Ljubuški",
        address: "Fra Lovre Šitovića br. 9",
        phone: "+387 39 831 408",
        parentId: 8,
        ppt: "88320",
        positionX: 45,
        positionY: 72,
        icon: "fa fa-map-marker"
    },
    {
        id: 43,
        businessUnit: "Posušje",
        address: "Domovinskog rata bb",
        phone: "+387 39 681 021",
        parentId: 8,
        ppt: "88240",
        positionX: 42,
        positionY: 65,
        icon: "fa fa-map-marker"
    },
    //children of Id 9
    {
        id: 44,
        businessUnit: "Sarajevo - Ispostava Centar",
        address: "Dženetića Čikma br. 8",
        phone: "+387 33 211 930",
        parentId: 9,
        ppt: "71000",
        positionX: 65,
        positionY: 54,
        icon: "fa fa-map-marker"
    },
    {
        id: 45,
        businessUnit: "Sarajevo - Ispostava Novi Grad",
        address: "Trg međunarodnog prijateljstva br. 17",
        phone: "+387 33 542 833",
        parentId: 9,
        ppt: "71160",
        positionX: 64.8,
        positionY: 52,
        icon: "fa fa-map-marker"
    },
    {
        id: 46,
        businessUnit: "Sarajevo - Ispostava Ilidža",
        address: "Rustempašina br. 52",
        phone: "+387 33 624 012",
        parentId: 9,
        ppt: "71210",
        positionX: 66.3,
        positionY: 50.2,
        icon: "fa fa-map-marker"
    },
    {
        id: 47,
        businessUnit: "Sarajevo - Ispostava Hadžići",
        address: "Hadželi br. 181",
        phone: "+387 33 420 895",
        parentId: 9,
        ppt: "71240",
        positionX: 68.6,
        positionY: 50.7,
        icon: "fa fa-map-marker"
    },
    // {
    //     id: 48,
    //     businessUnit: "Ilijaš",
    //     address: "Hašima Spahića br. 23",
    //     phone: "+387 33 407 914",
    //     parentId: 9,
    //     ppt: "71380",
    //     positionX: 69.6,
    //     positionY: 53,
    //     icon: "fa fa-map-marker"
    // },
    //children of id = 10
    {
        id: 49,
        businessUnit: "Tomislavgrad",
        address: "Mijata Tomića bb",
        phone: "+387 34 353 981",
        parentId: 10,
        ppt: "80240",
        positionX: 36,
        positionY: 54,
        icon: "fa fa-map-marker"
    },
];

export { businessUnits }